function is_empty(text) {
  return (text.toString().length === 0 || !text.toString().trim());
};

function replaceAll(text, oldString, newString) {
    //if replace is not sent, return original string otherwise it will
    //replace search string with 'undefined'.
    if (newString === undefined) {
        return text.toString();
    }
    return text.replace(new RegExp(oldString, 'g'), newString);
};


function humanize(str) {
  if (str == null || is_empty(str)) {
    return
  }
  return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/[-\s]+/g, ' ')
      .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
}

export { is_empty, replaceAll, humanize }