import {
    Controller
} from "stimulus"

export default class extends Controller {

    connect() {
        this.hookToggles()
    }

    hookToggles() {
        const items = this.element.querySelectorAll('.button')
        if (items.length > 0) {
            let self = this
            items.forEach(function (item) {
                item.addEventListener('click', function (event) {
                    self.resetToggles(items)
                })
            })
        }
    }

    resetToggles(items) {
        items.forEach(function (item) {
            item.classList.toggle('is-selected')
        })
    }
}