import { ApplicationController, FIELD_TYPE } from "utils/application-controller"
import { is_empty, replaceAll } from 'utils/utils'


export default class extends ApplicationController {
	
    static targets = ["structure"]
    
    initialize() {
       this.renderFields()
        document.querySelectorAll('fieldset').forEach((el, i) => {
            let select = el.querySelector("select[name='type']")
            let index = select.selectedIndex
            let typeValue = select.options[index].value
            el.className = typeValue
        })
    }
    
    setOptions(fieldObject, element) {
        var options = fieldObject.options
        switch(fieldObject.type) {
        case FIELD_TYPE.single:
        case FIELD_TYPE.multi:
            let choiceList = element.querySelector('ol.choice-options')
            var choiceTemplate = choiceList.children[0]
            choiceList.innerHTML = ""
            options.forEach((option, i) => {
                if (!is_empty(option)) {
                    var item = document.createElement('li')
                    item.innerHTML = choiceTemplate.innerHTML
                    item.querySelector('input').value = option
                    item.querySelector('input').placeholder = ''
                    choiceList.append(item)
                }
            })
            break;
        case FIELD_TYPE.range:
            let minRange = element.querySelector("input[name='range-min']")
            let maxRange = element.querySelector("input[name='range-max']")
            minRange.value = fieldObject.minRange
            maxRange.value = fieldObject.maxRange
            break;
        case FIELD_TYPE.image:
        case FIELD_TYPE.photo:
            let maxMedia = element.querySelector("input[name='media-max']");
            maxMedia.value = fieldObject.maxMedia
            break;
        default:
            break;
        }
        return fieldObject;
    }
    
    renderFields() {
        console.log("renderFields")
        let structure = this.structureTarget.value
        if (is_empty(structure)) {
            return
        }
        let json = JSON.parse(structure)
        let isEmpty = (json || []).length === 0
        if (isEmpty) {
            return
        }
        document.querySelector('ol.fields').innerHTML = ""
        json.forEach((field, index) => {
            if (field.type == FIELD_TYPE.coordinates) {
                return
            }
            var el = this.createFieldsetElement()
            el.querySelector("select[name='type']").value = field.type
            el.querySelector("input[name='id']").value = field.id
            el.querySelector("input[name='name']").value = field.name
            el.querySelector("input[name='description']").value = field.description
            let requiredCbx = el.querySelector("input[name='required']")
            requiredCbx.checked = field.required
            requiredCbx.id = "required-"+field.id
            let parent = requiredCbx.parentNode
            let label = parent.querySelector("label")
            label.htmlFor = requiredCbx.id
            this.setOptions(field, el)
            document.querySelector('ol.fields').append(el)
        })
    }
}
