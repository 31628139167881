import { ApplicationController } from "utils/application-controller"

export default class extends ApplicationController {
    
	typeChanged(event) {
        let element = this.element
        let index = element.selectedIndex
        let typeValue = type.options[index].value
        let parentNode = element.closest("fieldset") 
        parentNode.className = typeValue
        const bucketController = this.getControllerByIdentifier("bucket")
        bucketController.resetFieldset(parentNode)
	}
    
	removeField(event) {
        console.log("remove")
		event.preventDefault()
		var current = this.element.closest("li")
		if (document.getElementsByTagName('fieldset').length < 2) {
			return alert("You need at least one field")
		}
		current.remove()
	}
	
	insertChoice(event) {
		var item = document.createElement('li')
		let current = this.element.parentNode
		item.innerHTML = current.innerHTML
		item.querySelector("input").placeholder = ''
		current.parentNode.insertBefore(item, current.nextSibling)
		event.preventDefault()
	}
	
	removeChoice(event) {
		event.preventDefault()
		let current = this.element.parentNode
		let list = current.parentNode
		if (list.querySelectorAll('li').length <= 2) {
			return alert("You need at least 2 choices")
		}
		current.remove()
	}
}