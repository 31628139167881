import { Controller } from "stimulus"
import Rails from "rails-ujs"

export const FIELD_TYPE = {
    string: 'string',
    text: 'text',
    single: 'single',
    multi: 'multi',
    range: 'range',
    image: 'image',
    photo: 'photo',
    boolean: 'boolean',
    coordinates: "coordinates"
};

export class ApplicationController extends Controller {
    
    // Ajax
    railsUpdate(url, field, value) {
        return new Promise((resolve, reject) => {
            const data = new FormData()
            data.append(field, value)
            Rails.ajax({
                url,
                type: "PUT",
                dataType: 'json',
                data,
                success: data => {
                    resolve(data)
                },
                error: (_jqXHR, _textStatus, errorThrown) => {
                    reject(errorThrown)
                }
            });
        });
    }
    
    getControllerByIdentifier(identifier) {
        return this.application.controllers.find(controller => {
            return controller.context.identifier === identifier
        });
    }
  
    createFieldsetElement() {
        let fields = document.querySelector('script[data-template="field-template"]').textContent
        var el = document.createElement('li')
        el.innerHTML = fields
        return el
    }
}
