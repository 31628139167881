import { Controller } from "stimulus"
export default class extends Controller {
  
    static targets = [ 'map' ]
  
    connect() {
        this.init_map()
    }
    
    fit_bounds() {
        let map_bounds = new google.maps.LatLngBounds()

        map_bounds.extend(new google.maps.LatLng(this.bounds.min_latitude, this.bounds.min_longitude))
        map_bounds.extend(new google.maps.LatLng(this.bounds.max_latitude, this.bounds.max_longitude))

        this.map.fitBounds(map_bounds)
        this.map.setCenter(map_bounds.getCenter())
    }

    init_map() {
		let latLng = new google.maps.LatLng(parseFloat(this.coordinates[0]), parseFloat(this.coordinates[1]))
		this.map = new google.maps.Map(this.mapTarget, {
			zoom: 13,
			center: latLng,
			disableDefaultUI: true,
			zoomControl: true
        })
        let activeIcon = 'https://s3.amazonaws.com/static.todata.net/icons/marker_active.svg';
		let marker = new google.maps.Marker({
            position: latLng,
            icon: activeIcon,
			map: this.map
		})
        google.maps.event.trigger(this.map, 'resize')
    }
    
    get bounds() {
        if (!this._bounds) this._bounds = new Bounds(this.maps_data.competitors)

            return this._bounds
    }
    
    get coordinates() {
        return JSON.parse(this.element.getAttribute("data-coordinates"))
    }
}