import { ApplicationController } from "utils/application-controller"
import { is_empty } from "utils/utils"

export default class extends ApplicationController {
	static targets = ["payment"]

	initialize() {
		this.buttonsHook()
	}

	toggleCompany(event) {
		this.element.classList.toggle(event.target.value)
	}

	buttonsHook() {
		let self = this
		this.paymentTarget.value = ""
		paypal
			.Buttons({
				style: {
					color: "blue"
				},
				onClick: function(data, actions) {
					let form = self.checkoutForm
					if (form.checkValidity()) {
						return actions.resolve()
					} else {
						form.reportValidity()
						self.validateFields()
						return actions.reject()
					}
				},
				createOrder: function(data, actions) {
					return actions.order.create({
						intent: "CAPTURE",
						purchase_units: [
							{
								amount: {
									currency_code: "USD",
									value: "179.88"
								},
								reference_id: self.data.get('reference')
							}
						],
						application_context: {
							brand_name: "todata.net",
							landing_page: "BILLING",
							shipping_preference: "NO_SHIPPING",
							payment_method: {
								payer_selected: "PAYPAL",
								payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED"
							}
						}
					})
				},
				onApprove: function(data, actions) {
					// Capture the funds from the transaction
					return actions.order.capture().then(function(details) {
						// Send order id to the backend
						let orderID = data.orderID
						self.submitWithID(orderID)
					  })
				},
				onCancel: function(data) {
					console.log("cancelled by buyer")
				},
				onError: function(err) {
					// Show an error page here, when an error occurs
					console.log("paypal error: " + err)
				}
			})
			.render("#paypal-button-container")
	}

	submitWithID(orderID) {
		this.paymentTarget.value = orderID
		this.checkoutForm.submit()
		console.log("successfully created order " + orderID)
	}

	validateFields() {
		;[this.countryField].forEach((field, i) => {
			if (is_empty(field.value)) {
				field.closest(".control").classList.add("field-with-errors")
			}
		})
	}

	get checkoutForm() {
		return document.querySelector("form")
	}

	get countryField() {
		return document.querySelector("#payment_country")
	}

	get paypalContainer() {
		return document.querySelector("#paypal-button-container")
	}
}
