import { ApplicationController } from "utils/application-controller"

export default class extends ApplicationController {
	
    connect() {
        this.detectAndRedirect()
    }
    
    detectAndRedirect(){
		var now = new Date().valueOf()
		var userAgent = this.mobileOperatingSystem
		// Prevent loop for people returning to the the browser
		if (new Date().valueOf() - now > 100) return;
		
		if (/android/i.test(userAgent)) {
    		window.location.href = "https://play.google.com/store/apps/details?id=net.todata.android&referrer="+this.token
    	}
    	if (/ios/i.test(userAgent) ) {
            //document.cookie = `invite_token=${this.token}`
    		window.location.href = "https://apps.apple.com/us/app/gab-guichet-bancaire-au-maroc/id1356579570"
		}

    	setTimeout(function(){
    	    window.location.href = "http://todata.net"
    	}, 1500)
    }
    
    get token() {
        return this.data.get("token")
    }
    
    get mobileOperatingSystem() {
    	var userAgent = navigator.userAgent || navigator.vendor || window.opera

    	if (/windows phone/i.test(userAgent)) {
    		return "Windows Phone"
    	}

    	if (/android/i.test(userAgent)) {
    		return "android"
    	}

    	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    		return "iOS"
    	}

    	return userAgent
    }
	
}