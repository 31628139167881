import { ApplicationController } from "utils/application-controller"
import Rails from "rails-ujs"

export default class extends ApplicationController {
    static targets = ["list"];
   
    connect() {
        this.render()
    }
    
    render() {
        let url = this.data.get("id")
        Rails.ajax({
            url,
            type: "GET"
        });
    }
	
}