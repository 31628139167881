// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { ApplicationController, FIELD_TYPE, } from "utils/application-controller"
import { is_empty, replaceAll } from 'utils/utils'
import validate from 'validate.js'

const OPTION_FIELDS = [FIELD_TYPE.single, FIELD_TYPE.multi]
const MEDIA_FIELDS = [FIELD_TYPE.image, FIELD_TYPE.photo]

export default class extends ApplicationController {
        	
    insert(event) {
        let el = this.createFieldsetElement()
        document.querySelector('ol.fields').append(el)
        window.scrollTo(0, document.body.scrollHeight)
        event.preventDefault()
    }
    
    toggle(event) {
        let newValue = !event.target.checked
        let parent = this.element.parentNode
        let label = this.element.labels[0]
        this.railsUpdate(`${this.id}`, "bucket[archived]", newValue).then(function(value) {
            parent.classList.toggle("archived")
            if (label) {
                label.innerText = newValue ? "archived" : "active"
            }
        })
    }
	
    submit(event) {
       event.preventDefault()
        var fields = []
        var constraints = {
            name: {  
                presence: {allowEmpty: false}
            },
            options: function(value, attributes, attributeName, options, constraints) {
                if (OPTION_FIELDS.includes(attributes.type) == false) return null
                return {
                    presence: {allowEmpty: false}
                }
            },
            maxMedia: function(value, attributes, attributeName, options, constraints) {
                if (MEDIA_FIELDS.includes(attributes.type) == false) {
                    return null
                }
                return {
                    presence: {allowEmpty: false},
                    numericality: {isInteger: true}
                }
            },
            minRange: function(value, attributes, attributeName, options, constraints) {
                if ([FIELD_TYPE.range].includes(attributes.type) == false) {
                    return null
                }
                return {
                    presence: {allowEmpty: false}
                }
            },
            type: {}
        }
        
        document.querySelectorAll('fieldset').forEach((el, i) => {
            this.resetFieldset(el)
            let field = this.buildField(el, i)
            let errors = validate(field, constraints)
            if (errors) {
                this.showErrors(el, errors || {})
            } 
            fields.push(field)
        })
        
        if (this.isFormValid == false) {
            return false
        }
        
        let coordinates = document.getElementById('coordinates')
        if (coordinates.checked) {
            fields.push(this.buildCoordinates())
        }
        this.structure.value = JSON.stringify(fields)
        this.bucketForm.submit()
    }
    
    createFieldsetElement() {
        var el = super.createFieldsetElement()
        let fields = el.innerHTML
        let newID = this.fieldId
        let oldID = el.querySelector("input[name='id']").value
        el.innerHTML = replaceAll(fields, oldID, newID)
        return el
    }
	
    buildCoordinates() {
        var object = {
            name: 'Recorded location',
            id: this.fieldId,
            type: 'coordinates',
            required: true,
            sort_order: this.nextSortOrder
        }
        return object
    }
	
    buildField(el, index) {
        let order = index + 1
        let id = el.querySelector("input[name='id']")
        let type = el.querySelector("select[name='type']")
        let typeValue = type.options[type.selectedIndex].value
        let fieldName = el.querySelector("input[name='name']")
        let fieldDesc = el.querySelector("input[name='description']")
        let required = el.querySelector("input[name='required']")
        var fieldObject = {
            id: id.value,
            type: typeValue,
            name: fieldName.value,
            description: fieldDesc.value,
            required: required.checked,
            sort_order: order
        }		
        return this.getOptions(fieldObject, el)
    }
	
    getOptions(fieldObject, element) {
        switch(fieldObject.type) {
        case FIELD_TYPE.single:
        case FIELD_TYPE.multi:
            var options = []
            var choiceFields = element.querySelectorAll("input[class='choice-input']")
            choiceFields.forEach((el, i) => {
                let value = el.value
                if (!is_empty(value)) {
                    options.push(el.value)
                }
            })
            fieldObject.options = options
            break
        case FIELD_TYPE.range:
            let minElement = element.querySelector("input[name='range-min']")
            let maxElement = element.querySelector("input[name='range-max']")
            fieldObject.minRange = minElement.value ? parseFloat(minElement.value) : null
            fieldObject.maxRange = maxElement.value ? parseFloat(maxElement.value) : null
            break
        case FIELD_TYPE.image:
        case FIELD_TYPE.photo:
            let maxMedia = element.querySelector("input[name='media-max']")
            fieldObject.maxMedia = maxMedia.value ? parseInt(maxMedia.value) : null
            break
        default:
            break
        }
        return fieldObject
    }
    
    get id() {
        return this.data.get("id")
    }
	
    get fieldId() {
        return Math.random().toString(36).substr(2, 8)
    }
	
    get nextSortOrder() {
        return document.getElementsByTagName('fieldset').length + 1
    }
    
    get isFormValid() {
        return document.querySelectorAll('fieldset.has-error').length == 0
    }

    get bucketForm() {
        return document.querySelector('form')
    }

    get structure() {
        return this.bucketForm.querySelector('#bucket_structure')
    }
    
    // Validations
    showErrors(fieldset, errors) {
      // loop through all errors types and show the errors for that type
        for (const key of Object.keys(errors)) {
            let fieldErrors = errors[key]
            if (fieldErrors && fieldErrors.length > 0) {
                this.showErrorsFor(key, fieldset, fieldErrors)    
            }
        }
    }

  // Shows the errors for a specific input
  showErrorsFor(type, fieldset, errors) {
      fieldset.classList.add("has-error")
      //check if we're validating the name field or any other option
      let target = type == 'name' ? type : 'options'
      let messages = fieldset.querySelector(".message-"+target)
      if (errors) {
          errors.forEach((error) => {
              this.addError(messages, error)
          })
      }
  }
   
  addError(messages, error) {
      var block = document.createElement("p")
      block.classList.add("help-block")
      block.classList.add("error")
      block.classList.add("has-text-danger")
      block.innerText = error
      messages.appendChild(block)
  }
  
  resetFieldset(fieldset) {
      fieldset.classList.remove("has-error")
      fieldset.querySelectorAll('div[class^="message-"]').forEach((item) => {
          item.innerHTML = ''
      })
  }
}
