import {
    ApplicationController
} from "utils/application-controller"
import Rails from "rails-ujs"

export default class extends ApplicationController {
    static targets = ["map"];

    initialize() {
        this.render()
    }

    render() {
        let layout = this.data.get("layout")
        // load map if layout is set
        if (layout == 'map') {
            this.initMap()
        } else {
            // default to loading the grid submissions
            let url = this.data.get("url")
            Rails.ajax({
                url,
                type: "GET"
            })
        }
    }

    initMap() {
        let latLng = new google.maps.LatLng(51.555, -0.118092)
        this.map = new google.maps.Map(this.mapTarget, {
            zoom: 13,
            center: latLng,
            disableDefaultUI: true,
            zoomControl: true
        })
        google.maps.event.trigger(this.map, 'resize')
        this.configureMap()
    }

    configureMap() {
        let map = this.map
        let infoWindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, 30)
        });

        // This will apply some styling to the infowindow
        infoWindow.addListener('domready', function() {
            // Reference to the DIV that wraps the content of infowindow
            var iwOuter = document.querySelector('.gm-style-iw')
            // this is the div that holds the infowindow background/shadow/tooltip
            iwOuter.style.padding = 0
        });

        // on map click close infoWindow
        google.maps.event.addListener(map, 'click', function () {
            infoWindow.close()
        });

        if (markers.length > 0) {
            this.setMarkers(infoWindow)
        }

        this.resizeMap();
    }

    setMarkers(infoWindow) {
        let self = this
        let map = this.map
        let markerIcon = 'https://s3.amazonaws.com/static.todata.net/icons/marker.svg';
        let activeIcon = 'https://s3.amazonaws.com/static.todata.net/icons/marker_active.svg';
        var bounds = new google.maps.LatLngBounds();
        var activeMarker = null;

        markers.forEach((place, i) => {
            if (place.latitude == null && place.longitude == null) {
                return;
            }
            var latLng = new google.maps.LatLng(place.latitude, place.longitude)
            bounds.extend(latLng) //add the position to our bounds holder
            // Create a marker for each submission
            var marker = new google.maps.Marker({
                position: latLng,
                map: map,
                icon: markerIcon,
                title: place.title,
                id: place.id,
                details: place.details
            });

            // On marker click, load submission content
            marker.addListener('click', function () {
                self.loadContent(infoWindow, marker)
                if (activeMarker != null) {
                    activeMarker.setIcon(markerIcon);
                }
                marker.setIcon(activeIcon);
                activeMarker = marker;
            });
        })
        //fit all markers
        map.fitBounds(bounds);
    };

    resizeMap() {
        google.maps.event.trigger(this.map, "resize");
    }

    loadContent(infoWindow, marker) {
        let map = this.map
        let url = marker.details
        Rails.ajax({
            url,
            type: "GET",
            success: function(data) {
                console.log(data)
                infoWindow.setContent(data.body.firstChild);
                infoWindow.open(map, marker);
            }
        })
    }


}