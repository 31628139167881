import {
    Controller
} from "stimulus"
import {
    humanize
} from 'utils/utils'

export default class extends Controller {

    connect() {
        this.onLoadTrack()
    }

    // track load events
    onLoadTrack() {
        let load = this.data.get('load-event')
        if (load == null) {
            return
        }
        let event = humanize(load)
        analytics.track(event)
    }

    track() {
        let event = humanize(this.data.get('event'))
        analytics.track(event)
    }
}